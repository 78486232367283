import React, { useState, useRef } from "react"
import {
  Box,
  Text,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Select,
  HStack,
  RadioGroup,
  Radio,
  Center
} from "@chakra-ui/react"
import { VscWarning } from "react-icons/vsc";
import Turnstile from "react-turnstile";

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    //tickets: yup.string().required("Please select a ticket amount"),
    name: yup.string().required("Name is required"),
    email: yup.string().email("Please enter a valid email").required("Email is required"),
    message: yup.string().required("A message is required")
  }
  );

const errorStyles = {
    color: "#bf1650",
    fontSize: "1rem",
    textAlign: "left"
  };
  
function RequiredSpan() {
  return <span style={{ color: "#bf1650" }}>*</span>;
}

const VolunteerForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  })


  const watchData = watch();
  // console.log(watchData)
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [turnstileResponse, setTurnstileReponse] = useState(false);

  const formRef = useRef(null);
  
  const onSubmit = async (data) => {
    setProcessing(true);
    // Add turnstileReponse to data object
    data = Object.assign({ turnstileResponse: turnstileResponse }, data);

    const JSONData = JSON.stringify(data);
    const formData = new URLSearchParams(data)

    const endpoint = `/.netlify/functions/send-contact-form`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSONData,
    }

    await fetch(endpoint, options)
    .then((response) => {
      if (response.status === 200) {
          //console.log(JSON.stringify(data));
          setSucceeded(true);
          reset();
      } else {
          alert('Failed to validate CAPTCHA response. Please try again.');
          setProcessing(false);
      }
    })
    .then(
      fetch(`https://script.google.com/macros/s/AKfycbyk5KsUxWdSZf3lYBsvT-1a4xXpYQgomgyXrvYt1I5bxAdfNdafvRTjzpuEc7ryTbm7lQ/exec`,
      {
        method: "POST",
        body: formData
      }).then((res) => {
        if (res.status === 200) {
          console.log('success')
        } else {
          console.log('error')
        }
      })
    );
    // const result = await response.json();

    // console.log(response)

    // if (result === 'success') {
    //   setProcessing(false);
    //   reset();
    //   setSucceeded(true);
    // } else {
    //   alert('Failed to validate CAPTCHA response. Please try again.');
    //   setProcessing(false);
    // }

    // fetch('/.netlify/functions/send-contact-form', {
    //           method: 'POST',
    //           headers: {
    //             "Content-Type": "application/json"
    //         },            
    //             body: JSON.stringify(data)
    //     })
    //     .then((response) => {
    //         if (response.status === 200) {
    //             console.log(JSON.stringify(data));
    //             setSucceeded(true);
    //             reset();
    //         } else {
    //             console.log('network error')
    //         }
    //     })
    
      }

  const SuccessMessage = () => (
    <Box>
    <Alert status="success"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
      borderRadius="1rem"
      >
    <AlertIcon boxSize='40px' mr={0}/>
      <AlertTitle mb="4">
        Message submitted successfully!
        </AlertTitle>
        <AlertDescription maxWidth='md'>
    Thanks for your submission. We'll be in touch soon.
  </AlertDescription>
    </Alert>
  </Box>
  )


  return (
    <>
      <Box w="100%" mb="24">
      { succeeded ? <SuccessMessage /> : (
        <>

        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <Box  mb={8} pr={{base: 0, lg: '2'}}>
                  <FormControl id="name">
                    <FormLabel>Your Name<RequiredSpan /></FormLabel>
                    <Input type="text"
                        {...register("name")}
                        autoComplete="on"
                    />
                     {errors.name && (
                        <HStack spacing="5px" style={errorStyles}>
                            <VscWarning /> <Box>{errors.name?.message}</Box>
                        </HStack>
                    )} 
                  </FormControl>
                </Box>
            <Flex w="100%" flexWrap="wrap">
                <Box w={{base: '100%', lg: '50%'}} mb={8} pr={{base: 0, lg: '2'}}>
                  <FormControl id="email">
                    <FormLabel>Email address<RequiredSpan /></FormLabel>
                      <Input type="email" 
                        {...register("email")}
                        autoComplete="on"
                        />
                        {errors.email && (
                            <HStack spacing="5px" style={errorStyles}>
                                <VscWarning /> <Box>{errors.email?.message}</Box>
                            </HStack>
                        )} 
                </FormControl>
                </Box>
                <Box w={{base: '100%', lg: '50%'}} mb={8} >
                  <FormControl id="name">
                    <FormLabel>Phone</FormLabel>
                    <Input type="text"
                        {...register("phone", {
                          pattern: {
                            value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i
                         }
                      } )}
                        autoComplete="on"
                    />
                    {errors.phone && <Text color="#bf1650">Please enter a valid phone number.</Text>}
                  </FormControl>
                </Box>
            </Flex>
            
        <Box mb={8}>
          <FormControl id="volunteer">
            <FormLabel>Would you like to volunteer at an event?</FormLabel>
            <Select placeholder='Select option' {...register("volunteer")}>
              <option value='snowscapes'>Snowscapes</option>
              <option value='plein air festival'>Plein Air Festival</option>
              <option value='shakespeare in the parks'>Shakespeare In The Parks</option>
              <option value='first friday art walks'>First Friday Art Walks</option>
              <option value='downtown sounds'>Downtown Sounds</option>
              <option value='driggs trick or treat'>Driggs Trick or Treat</option>
              <option value='scarecrow contest'>Scarecrow Contest</option>
            </Select>
          </FormControl>
        </Box>

        <Box mb={8}>
          <FormControl id="how-did-you-hear">
            <FormLabel>How did you hear about us?</FormLabel>
            <Select placeholder='Select option' {...register("howDidYouHear")}>
              <option value='website'>Website</option>
              <option value='email'>Email communication</option>
              <option value='e-news'>DDA E-News</option>
              <option value='social media'>Social media</option>
              <option value='TV or radio'>TV or radio</option>
              <option value='newspaper'>Newspaper ad or article</option>
              <option value='magazine'>Magazine ad or article</option>
              <option value='word of mouth'>Word of mouth</option>
            </Select>
          </FormControl>
        </Box>

        <Box>
            <FormControl id="message" mb={8}>
            <FormLabel>Message<RequiredSpan /></FormLabel>
                <Textarea placeholder=""
                    {...register("message")}
                    />
                    {errors.message && (
                        <HStack spacing="5px" style={errorStyles}>
                            <VscWarning /> <Box>{errors.message?.message}</Box>
                        </HStack>
                    )} 
            </FormControl>
        </Box>
        <Box>
          <FormControl id="mailinglist" mb={8} as="fieldset">
            <FormLabel as="legend">Would you like to join our mailing list?</FormLabel>
              <RadioGroup defaultValue="yes">
                <HStack spacing="4">
                  <Radio value="yes" {...register("mailinglist")}>Yes</Radio>
                  <Radio value="no" {...register("mailinglist")}>No</Radio>
                </HStack>
              </RadioGroup>
          </FormControl>
        </Box>
       <Center>
          <Turnstile 
              sitekey={process.env.GATSBY_TURNSTILE_SITE_KEY}
              theme='light'
              onVerify={(token) => setTurnstileReponse(token)}
            />
       </Center>
          <Box mt="4">
          <Button
            className="submit"
            variant="primary" 
            disabled={processing}
            type="submit"
            mt={4}
            isLoading={processing}
            loadingText='Submitting'
            >
              Submit
          </Button>
          </Box>
        </form>
        </>
      )
}
      </Box>
    </>
  )
}

export default VolunteerForm
